import './Footer.css';
import logo from 'images/logo_transparent.png';

export default function Footer() {
  return (
    <div className='footer'>
      <div className='container footer-content'>
        <img src={logo} alt='Logo' className='footer-logo' />
        <p className='description'>Copyright © 2023 All rights reserved</p>
      </div>
    </div>
  );
}
