import './Buttons.css';
import { Link } from 'react-router-dom';

export default function PrimaryButton({ buttonText, onClick, linkTo }) {
  return (
    <Link className='primary-button' onClick={onClick} to={linkTo}>
      {buttonText}
    </Link>
  );
}
