import Hero from 'components/Hero/Hero';
import Main from 'components/Main/Main';
import { Fragment } from 'react';

export default function Home() {
  return (
    <Fragment>
      <Hero />
      <Main />
    </Fragment>
  );
}
