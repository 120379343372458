import './Demo.css';
import UnityApp from 'components/UnityApp/UnityApp';

const appName = 'Drone';

const appData = {
  loaderUrl: `${appName}/Build/${appName}.loader.js`,
  dataUrl: `${appName}/Build/${appName}.data`,
  frameworkUrl: `${appName}/Build/${appName}.framework.js`,
  codeUrl: `${appName}/Build/${appName}.wasm`,
};

export default function Demo() {
  return (
    <div className='container demo-content'>
      <h3>Kinematics of a flying drone</h3>
      <p className='description'>
        Our interactive web apps bring science concepts to life through engaging
        visualisations. This app was developed for the first-year physics
        courses at Ecole Polytechnique Fédérale de Lausanne (EPFL). It deals
        with the motion of objects relative to different reference frames.
      </p>
      <p className='description'>Press the play button to try it yourself!</p>
      <UnityApp {...appData} />
    </div>
  );
}
