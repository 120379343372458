import './About.css';
import ellipseGIF from 'gifs/ellipse.gif';
import ProfileCard from 'components/Cards/ProfileCard';
import apProfilePic from 'images/ap_profile.jpg';
import agProfilePic from 'images/ag_profile.jpg';
import ContactButton from 'components/Buttons/ContactButton';

const apBullets = [
  'PhD in physics',
  'Simulation developer',
  'Video game creator',
  'Educational video producer',
];
const agBullets = [
  'PhD in astrohpysics',
  'Graphic designer',
  'Software developer',
  'Science communicator',
];

export default function About() {
  return (
    <div className='container about-content'>
      <div className='about-headline'>
        <h1>Interactive, real-time 3D simulations</h1>
        <img src={ellipseGIF} alt='Animated Ellipse' />
      </div>
      <h2 className='about-mission'>Our mission</h2>
      <p>
        We want to unlock the full potential of STEM students everywhere by
        pioneering the most effective tools for mastering science. Leveraging
        powerful video game technology, our real-time 3D simulations engage
        learners through hands-on exploration and foster deep scientific
        intuitions. We also help educators deliver those eureka moments more
        often by completing their teaching toolbox, both in traditional and
        hybrid classrooms.
      </p>
      <h2>Who we are</h2>
      <div className='about-profiles'>
        <ProfileCard
          name='Dr Austin Peel'
          image={apProfilePic}
          bullets={apBullets}
        />
        <ProfileCard
          name='Dr Aymeric Galan'
          image={agProfilePic}
          bullets={agBullets}
        />
      </div>
      <div className='about-cta'>
        <h2>Have a simulation idea? We'd love to hear from you.</h2>
        <ContactButton
          buttonText='Contact Us'
          emailAddress='contact@intuitivescience.ch'
        />
      </div>
    </div>
  );
}
