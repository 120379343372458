import Header from 'components/Header/Header';
import Home from 'pages/Home';
import About from 'pages/About';
import Demo from 'pages/Demo';
import PageNotFound from 'pages/PageNotFound';
import Footer from 'components/Footer/Footer';
import { HashRouter, Routes, Route } from 'react-router-dom';

export default function App() {
  return (
    <div>
      <HashRouter basename='/'>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </HashRouter>
      <Footer />
    </div>
  );
}
