import DiscoverySection from './DiscoverySection';
import TrustedSection from './TrustedSection';

export default function Main() {
  return (
    <div>
      <DiscoverySection />
      <TrustedSection />
    </div>
  );
}
