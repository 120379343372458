import './DiscoverySection.css';
import DiscoveryCard from 'components/Cards/DiscoveryCard';
import School from '@mui/icons-material/School';
import Psychology from '@mui/icons-material/Psychology';
import ExitToApp from '@mui/icons-material/ExitToApp';

const card1 = {
  icon: <School fontSize='large' />,
  title: 'Higher education',
  description:
    'University-level topics in physics, mathematics, and engineering',
};

const card2 = {
  icon: <Psychology fontSize='large' />,
  title: 'Transforming teaching',
  description: 'Research-backed active learning scenarios for educators',
};

const card3 = {
  icon: <ExitToApp fontSize='large' />,
  title: 'Seamless integration',
  description: 'Plugins for popular learning management systems like Moodle',
};

export default function DiscoverySection() {
  return (
    <div className='discovery-section'>
      <div className='container discovery-cards'>
        <DiscoveryCard {...card1} />
        <DiscoveryCard {...card2} />
        <DiscoveryCard {...card3} />
      </div>
    </div>
  );
}
