import './DiscoveryCard.css';

export default function DiscoveryCard(props) {
  return (
    <div className='discovery-card'>
      <div className='discovery-card-icon'>{props.icon}</div>
      <h3>{props.title}</h3>
      <p className='description'>{props.description}</p>
    </div>
  );
}
