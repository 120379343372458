import './TrustedSection.css';
import ContactButton from 'components/Buttons/ContactButton';
import gif1 from 'gifs/coupled_oscillations.gif';
import gif2 from 'gifs/rotating_cannon2.gif';
import gif3 from 'gifs/flying_drone.gif';

// Swiper
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const createCarousel = (images) => (
  <Swiper
    modules={[Autoplay, Navigation, Pagination]}
    spaceBetween={30}
    loop
    autoplay={{
      delay: 10000,
      disableOnInteraction: false,
    }}
    navigation
    pagination={{ clickable: true }}
  >
    {images.map((image, index) => (
      <SwiperSlide key={index}>
        <img src={image} alt='' />
      </SwiperSlide>
    ))}
  </Swiper>
);

export default function TrustedSection() {
  return (
    <div className='container trusted-section'>
      <div className='trusted-content'>
        <h2>Trusted by world-leading universities</h2>
        <p>
          Physics professors at EPFL are using Intuitive Science technology in
          the classroom to help them teach difficult and abstract concepts
          through immersive, interactive web apps.
        </p>
        <span>
          <p>Let's create interactive simulations for your course!</p>
          <ContactButton
            buttonText='Book a consultation'
            emailAddress='contact@intuitivescience.ch'
          />
        </span>
      </div>
      <div className='image-carousel'>{createCarousel([gif1, gif2, gif3])}</div>
    </div>
  );
}
