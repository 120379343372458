import './Header.css';
import logo from 'images/logo_full.png';
import { Link } from 'react-router-dom';

const handleClick = () => {
  window.location.href = `mailto:contact@intuitivescience.ch`;
};

export default function Header() {
  return (
    <header className='container header'>
      <img src={logo} alt='Logo' className='header-logo' />
      <nav>
        {/* <a href='/'>Home</a>
        <a href='#about'>About</a>
        <a href='#contact'>Contact</a> */}
        <Link to='/'>Home</Link>
        <Link to='/about'>About</Link>
        <Link to='/demo'>Demo</Link>
        <Link onClick={handleClick}>Contact</Link>
      </nav>
    </header>
  );
}
