import { Fragment } from 'react';
import './ProfileCard.css';

export default function ProfileCard({ name, image, bullets }) {
  // Bulleted list
  const listItems = bullets.map((e) => <li>{e}</li>);

  return (
    <div className='profile-card'>
      <Fragment>
        {image ? (
          <img className='profile-pic' src={image} alt='Profile pic' />
        ) : (
          <div className='profile-pic placeholder'></div>
        )}
      </Fragment>
      <h3>{name}</h3>
      <h3 className='italic'>Co-founder</h3>
      <ul className='description'>{listItems}</ul>
    </div>
  );
}
