import './Hero.css';
import { useRef } from 'react';
import heroImage from 'images/SHO.png';
import heroVideo from 'videos/SHO-loop.mp4';
import PrimaryButton from 'components/Buttons/PrimaryButton';

export default function Hero() {
  const ref = useRef(null);

  const loop = () => {
    ref.current.play();
  };

  return (
    <div className='container hero-section'>
      <div className='hero-video'>
        <video
          id='video'
          ref={ref}
          autoPlay
          muted={true}
          src={heroVideo}
          onEnded={loop}
          poster={heroImage}
        ></video>
      </div>
      <div className='hero-content'>
        <h1 className='hero-tagline'>
          Breathing new life into science education
        </h1>
        <PrimaryButton buttonText='Try the demo' linkTo='/demo' />
      </div>
    </div>
  );
}
